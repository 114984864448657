<template>
  <section
    class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
    id="services"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Dasar Hukum</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Berikut adalah dasar-dasar hukum yang melahirkan program GEBRAK TEGAS Kabupaten Tangerang.</p>
      </div><!--end grid-->

      <div class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 grid-cols-1 gap-6 mt-6">
        <div
          v-for="item in servicesData"
          :key="item.id"
          class="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit"
        >
          <div class="flex items-center justify-center size-10 -rotate-45 bg-gradient-to-r from-transparent to-teal-500/10 text-teal-500 text-center rounded-full group-hover:bg-teal-500/10 duration-500">
            <i
              :data-feather="item.icon"
              class="size-5 rotate-45"
            ></i>
          </div>

          <div class="content mt-6 relative z-1">
            <a
              target="__blank"
              :href="item.href"
              class="title text-lg font-semibold hover:text-teal-500"
            >{{item.title}}</a>
            <p class="text-slate-400 mt-3">{{item.desc}}</p>

            <div class="mt-6">
              <a
                :href="item.href"
                target="_blank"
                class="text-teal-500"
              >Lihat lebih lengkap.. <i class="mdi mdi-chevron-right align-middle"></i></a>
            </div>

          </div>

          <div class="absolute bottom-0 -end-16">
            <i
              :data-feather="item.icon"
              class="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500"
            ></i>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      servicesData: [
        {
          id: 1,
          icon: "star",
          title: "Peraturan Presiden Nomor 18 Tahun 2020",
          desc: "Tentang Rencana Pembangunan Jangka Menengah Nasional Tahun 2020-2024 (Lembaran Negara Republik Indonesia Tahun 2020 Nomor 10);",
          href: "https://peraturan.bpk.go.id/Details/131386/perpres-no-18-tahun-2020",
        },
        {
          id: 2,
          icon: "star",
          title: "Peraturan Presiden Nomor 72 Tahun 2021",
          desc: "Tentang Percepatan Penurunan stunting (Lembaran Negara Republik Indonesia Tahun 2021 Nomor 172);",
          href: "https://peraturan.bpk.go.id/Details/174964/perpres-no-72-tahun-2021",
        },
        {
          id: 3,
          icon: "star",
          title: "Instruksi Presiden Nomor 4 Tahun 2022 ",
          desc: "Tentang Percepatan Penghapusan Kemiskinan Ekstrem;.",
          href: "https://peraturan.bpk.go.id/Details/211477/inpres-no-4-tahun-2022",
        },
        {
          id: 4,
          icon: "star",
          title: "Peraturan Bupati Tangerang Nomor 85 Tahun  2022",
          desc: "Tentang Pedoman Pelaksanaan Program Penanggulangan Kemiskinan Ekstrem. (Berita Daerah Kab. Tangerang Tahun 2022 No 85);.",
          href: "https://peraturan.bpk.go.id/Details/266692/perbup-kab-tangerang-no-85-tahun-2022",
        },
        {
          id: 5,
          icon: "star",
          title: "Peraturan Bupati Tangerang Nomor 103 Tahun 2022",
          desc: "Tentang Percepatan Penurunan stunting (Berita Daerah Kabupaten Tangerang Tahun 2022 Nomor 103);.",
        },
        {
          id: 6,
          icon: "star",
          title: "Keputusan Bupati Tangerang Nomor 400/Kep.1176-Huk/2023",
          desc: "Tentang Pembentukan Tim Gerakan Bersama Atasi Kemiskinan Ekstrem dan Cegah Stunting.",
        },
      ],
    };
  },

  mounted() {
    feather.replace();
  },
};
</script>