<template>
  <section
    class="realtive md:py-24 py-16"
    id="agency"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Tim Gebrak Tegas</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Berikut adalah fungsi dan susunan keanggotaan dari Tim Gebrak Tegas.</p>
      </div><!--end grid-->

      <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
        <div class="lg:col-span-4 md:col-span-5">
          <div class="sticky top-20">
            <ul
              class="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li role="presentation">
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500"
                  :class="activeindex === '1' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange('1')"
                >

                  <span class="text-lg mt-2 block">Fungsi Tim Gebrak Tegas</span>
                </button>
              </li>
              <li role="presentation">
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500 mt-6"
                  :class="activeindex === '2' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange('2')"
                >
                  <span class="text-lg mt-2 block">Susunan Keanggotaan Tim Gebrak Tegas</span>

                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="lg:col-span-8 md:col-span-7">
          <div
            id="myTabContent"
            class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
          >

            <div
              :class="activeindex !== '1' ? 'hidden' : '' "
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <img
                src="../assets/images/bg/gebraktegas-2-min.png"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">Fungsi Tim Gebrak Tegas</h5>

                <p class="text-slate-400 mt-4">
                <ul>
                  <li>- Sinkronisasi perencanaan penganggaran program/kegiatan penanganan kemiskinan ekstrem dan cegah stunting;</li>
                  <li>- koordinasi pelaksanaan program/kegiatan penanganan kemiskinan ekstrem dan cegah stunting;</li>
                  <li>- fasilitasi program/kegiatan penanganan kemiskinan ekstrem dan cegah stunting; dan</li>
                  <li>- penyusunan laporan hasil pemantauan pelaksanaan penanganan kemiskinan ekstrem dan cegah stunting.</li>
                </ul>
                </p>

              </div>
            </div>

            <div
              :class="activeindex !== '2' ? 'hidden' : '' "
              id="dashboard"
              role="tabpanel"
              aria-labelledby="dashboard-tab"
            >
              <img
                src="../assets/images/bg/gebraktegas-3-min.png"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">Susunan Keanggotaan Tim Gebrak Tegas:</h5>
                <p class="text-slate-400 mt-4">
                <ul>
                  <li>- Pengarah, Ketua, Wakil Ketua, Sekretaris, </li>
                  <li>- Bidang Advokasi dan Diseminasi, Bidang Mobilisasi Sumber Daya, Bidang Pengendalian dan Pengawasan, Bidang Media Informasi dan Litbang</li>
                  <li>- Koordinator Wilayah</li>
                  <li>- Liaison Officer</li>
                  <li>- Koordinator Lapangan</li>
                </ul>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>


<script>
export default {
  data() {
    return {
      activeindex: "1",
    };
  },

  methods: {
    tabChange(index) {
      this.activeindex = index;
    },
  },
};
</script>